const { protocol, host } = window.location, api = `${protocol}//${host}/api/`;
// const api = "http://localhost:9001/api/";
// const api = "http://192.168.1.10:9001/api/";
// const api = "http://192.168.1.20:9001/api/";

// const api = 'https://erpsmt.in/api/';
// const api = 'https://pukaltechnologies.in/api/';
// const api = "http://shrifoods.erpsmt.in/api/";


export default api;
